import { Dialog } from "@headlessui/react";
import { useRef } from "react";
import { ClientOnly } from "remix-utils/client-only";

import { CrossIcon } from "~/components/svg/icons";
import { Wrapper } from "~/components/ui/wrapper";
import { cn } from "~/utils/classnames";

import "./Modal.css";
import type { ModalProps } from "./Modal.types";

/**
 * Composant modal
 */
export const Modal = ({
  title,
  description,
  children,
  size,
  isOpen,
  forceOpen = false,
  onClose,
  className,
  ...props
}: ModalProps) => {
  const divRef = useRef<HTMLDivElement>(null);

  return (
    <ClientOnly fallback={null}>
      {() => (
        <Dialog
          open={isOpen}
          onClose={() => onClose(false)}
          initialFocus={divRef}
          className={cn("Modal", className)}
          {...props}
        >
          <div className="Modal-bgOverlay" aria-hidden="true" />
          <Wrapper>
            <Dialog.Panel className={cn(`Modal--${size}`, "Modal-container")} ref={divRef}>
              {forceOpen ? null : (
                <CrossIcon className="Modal-icon" onClick={() => onClose(false)} />
              )}
              <Dialog.Title className="Modal-title">{title}</Dialog.Title>
              {description ? (
                <Dialog.Description className="Modal-description">{description}</Dialog.Description>
              ) : null}
              {children}
            </Dialog.Panel>
          </Wrapper>
        </Dialog>
      )}
    </ClientOnly>
  );
};
